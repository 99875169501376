import $ from 'jquery';

const $form = $('#mc-embedded-subscribe-form');

interface McResponse {
  result: 'success' | 'error';
  msg: string;
}

if ($form.length > 0) {
  $form.find('input[type="submit"]').on('click', (event) => {
    if (event) {
      event.preventDefault();
    }

    $.ajax({
      type: 'GET',
      url: $form.attr('action'),
      data: $form.serialize(),
      cache: false,
      dataType: 'jsonp',
      contentType: 'application/json; charset=utf-8',
      success: (data: McResponse) => {
        console.log(data);
        if (!data.msg) {
          return;
        }
        $('#mce-responses')
          .find(data.result === 'success' ? '#mce-success-response' : '#mce-error-response')
          .text(data.msg)
          .removeClass('hidden')
          .siblings()
          .addClass('hidden');
      },
      error: (error) => {
        console.log(error);
      },
    });
  });
}
